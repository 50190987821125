
import { CategoriesInCheckListModel } from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import Vue from "vue";
import Component from "vue-class-component";
import CategoryWithCriterionsList from "./CategoryWithCriterionsList.vue";

const IEditCheckListModalFormProps = Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    selectedCheckListId: {
      type: Number,
      required: true,
    },
  },
});

@Component({
  components: {
    CategoryWithCriterionsList,
  },
})
export default class EditCheckListModalForm extends IEditCheckListModalFormProps {
  private componentClass = "EditCheckListModalForm";

  //private categoriesList: CategoriesInCheckListModel [] = []

  async mounted() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL_LIST
    );
  }

  async getCategoriesList(id: number) {
    await this.$store.dispatch(
      StoreActionTypes.SET_CATEGORIES_WITH_CRITERIONS_FULL_LIST
    );
  }

  public get categoriesList() {
    return this.$store.getters.getCategoriesWithCriterionsFullList;
  }

  public get nestedList() {
    return this.$store.getters.getCriterionsList;
  }

  public getCriterionsList() {
    let list: number[] = [];

    // eslint-disable-next-line
    this.categoriesList.map((_, idx) => {
      const itemList = (
        this.$refs[
          `categoryWithCriterionsList${idx}`
        ] as CategoryWithCriterionsList
      )[0].getSelectedList();
      list = [...list, ...itemList];
    });

    return list;
  }

  get loadData(): boolean {
    return this.$store.getters.isCategoriesDataLoading;
  }
}
