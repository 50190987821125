
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { StoreActionTypes } from "@/store/types";

require("@/assets/images/logo.svg");

@Component({
  components: {
    FormInput,
    ActionButton,
  },
})
export default class SetPasswordForm extends Vue {
  private componentClass = "SetPasswordForm";

  private password = "";
  private repeatPassword = "";

  private loginLink = "/login";

  get isDataLoading() {
    return this.$store.getters.isAuthentificationDataLoading;
  }

  async onSubmit() {
    await this.$store.dispatch(StoreActionTypes.SET_PASSWORD, this.password);
    this.$router.push({ name: "loginPage", query: { passChanged: `true` } });
  }

  handlePasswordInputChange(text: string) {
    this.password = text;
  }

  handleRepeatPasswordInputChange(text: string) {
    this.repeatPassword = text;
  }

  get passwordMatched(): boolean {
    return this.password.length >= 3 && this.password == this.repeatPassword;
  }
}
