
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { StoreActionTypes } from "@/store/types";
import { LoginWithPasswordRequestModel } from "@/services/types";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";

require("@/assets/images/logo.svg");

@Component({
  components: {
    FormInput,
    ActionButton,
  },
})
export default class LoginForm extends Vue {
  private componentClass = "LoginForm";

  private login = "";
  private password = "";

  private resetPassLink = "/reset-password";

  get isDataLoading() {
    return this.$store.getters.isAuthentificationDataLoading;
  }

  async onSubmit() {
    await this.$store.dispatch(
      StoreActionTypes.LOGIN_WITH_PASSWORD,
      this.createLoginWithPasswordRequestModel()
    );
    if (isUserHasPermission(UserPermissions.VIEW_COMPANY_REPORTS)) {
      this.$router.push("/");
    } else {
      this.$router.push("/my-profile");
    }
  }

  createLoginWithPasswordRequestModel(): LoginWithPasswordRequestModel {
    return {
      login: this.login,
      password: this.password,
    };
  }

  handleLoginInputChange(text: string) {
    this.login = text;
  }

  handlePasswordInputChange(text: string) {
    this.password = text;
  }
}
