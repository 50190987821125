
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import {
  CreateCriterionRequestModel,
  CriterionTypeCode,
  CriterionOptionsItem,
} from "@/services/types";
import SelectCriterionType from "@/components/select/criterionType/SelectCriterionType.vue";
import SelectCategory from "@/components/select/category/SelectCategory.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { IconDefinition, faTimes } from "@fortawesome/free-solid-svg-icons";
import * as utils from "@/services/utils";

const ICreateCriterionModalFormProps = Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    selectedCategoryId: {
      type: Number,
      required: false,
      default: null,
    },
  },
});

interface OptionsRenderItem extends CriterionOptionsItem {
  idx: string;
}

@Component({
  components: {
    FormInput,
    SelectCriterionType,
    SelectCategory,
    ActionButton,
  },
})
export default class CreateCriterionModalForm extends ICreateCriterionModalFormProps {
  private componentClass = "CreateCriterionModalForm";
  private name = "";
  private knowledges = [
    {
      title: "",
      text: "",
    },
  ];
  private categoryId = 0;
  private criterionTypeSelectValue = "null";
  private deleteIcon: IconDefinition = faTimes;

  private prohibitionOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: null,
      idx: "prohibitionOption-0",
    },
    {
      name: "",
      nominalWeight: null,
      idx: "prohibitionOption-1",
    },
  ];

  private requirementOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: null,
      idx: "requirementOption-0",
    },
    {
      name: "",
      nominalWeight: null,
      idx: "requirementOption-1",
    },
  ];

  private customOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: null,
      idx: `customOption-0`,
    },
    {
      name: "",
      nominalWeight: null,
      idx: "customOption-1",
    },
  ];

  get showAddMoreKnowledgesBtn() {
    const last = this.knowledges[this.knowledges.length - 1];
    return last.title.length > 2 && last.text.length > 2;
  }

  mounted() {
    if (this.selectedCategoryId) {
      this.categoryId = this.selectedCategoryId;
    }
  }

  addKnowledge() {
    this.knowledges.push({
      title: "",
      text: "",
    });
  }

  nameChange(text: string) {
    this.name = text;
  }

  public getCreateCriterionRequestModel(): CreateCriterionRequestModel {
    return {
      name: this.name,
      categoryId: this.categoryId,
      typeCode: this.getCriterionTypeSelectValue(),
      options: this.getOptions(),
      notes: this.knowledges
        .filter((v) => v.title.length > 0)
        .map((v) => {
          return {
            title: v.title,
            text: this.replaceURLs(v.text),
          };
        }),
    };
  }

  replaceURLs(message) {
    if (!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }

  public get isOkBtnDisabled() {
    const model = this.getCreateCriterionRequestModel();
    return !(
      model.name.length === 0 ||
      model.categoryId === 0 ||
      model.options.length === 0 ||
      model.options[model.options.length - 1].name.length === 0 ||
      model.options[model.options.length - 1].nominalWeight === null
    );
  }

  handleCriterionTypeSelectChangeEvent(selected: string) {
    this.criterionTypeSelectValue = selected;
  }

  getCriterionTypeSelectValue(): CriterionTypeCode | null {
    if (this.criterionTypeSelectValue == "null") {
      return null;
    } else {
      return this.criterionTypeSelectValue as CriterionTypeCode;
    }
  }

  onSelectSelectCategory(id: number) {
    this.categoryId = id;
  }

  deleteCustomOption(index: number) {
    this.customOptions.splice(index, 1);
  }

  handleLength(value) {
    return value >= 1000 ? 1000 : value <= -1000 ? -1000 : value;
  }

  addCustomOptionItem() {
    const customOptionsLength = this.customOptions.length;
    let lastElementIdx = 0;

    if (customOptionsLength > 0) {
      const lastElementIndex = customOptionsLength - 1;
      lastElementIdx = parseInt(
        this.customOptions[lastElementIndex].idx.slice(
          this.customOptions[lastElementIndex].idx.indexOf("-") + 1
        )
      );
    }

    this.customOptions.push({
      name: "",
      nominalWeight: null,
      idx: `customOption-${lastElementIdx + 1}`,
    });
  }

  getOptions(): CriterionOptionsItem[] {
    const criterionType = this.criterionTypeSelectValue as CriterionTypeCode;
    let options: OptionsRenderItem[] = [];

    switch (criterionType) {
      case "CUSTOM":
        options = this.customOptions;
        break;
      case "REQUIREMENT":
        options = this.requirementOptions;
        break;
      case "PROHIBITION":
        options = this.prohibitionOptions;
        break;
    }

    return options.map((item) => ({
      name: item.name,
      nominalWeight: item.nominalWeight
        ? utils.convertCommaToDotAndRoundToTwo(item.nominalWeight.toString())
        : 0,
    }));
  }
}
