
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { StoreActionTypes } from "@/store/types";
import { ResetPasswordModel } from "@/services/types";

require("@/assets/images/logo.svg");

@Component({
  components: {
    FormInput,
    ActionButton,
  },
})
export default class ResetPasswordForm extends Vue {
  private componentClass = "ResetPasswordForm";

  private email = "";

  private loginLink = "/login";

  get isDataLoading() {
    return this.$store.getters.isAuthentificationDataLoading;
  }

  async onSubmit() {
    await this.$store.dispatch(
      StoreActionTypes.RESET_PASSWORD,
      this.createResetPasswordModel()
    );
    this.$router.push({ name: "loginPage", query: { passReseted: `true` } });
  }

  createResetPasswordModel(): ResetPasswordModel {
    return {
      email: this.email,
    };
  }

  handleEmailInputChange(text: string) {
    this.email = text;
  }
}
