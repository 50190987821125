
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import {
  EditCriterionRequestModel,
  CriterionTypeCode,
  CriterionOptionsItem,
  CriterionFullModel,
} from "@/services/types";
import SelectCriterionType from "@/components/select/criterionType/SelectCriterionType.vue";
import SelectCategory from "@/components/select/category/SelectCategory.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import { IconDefinition, faTimes } from "@fortawesome/free-solid-svg-icons";
import { StoreActionTypes } from "@/store/types";
import * as utils from "@/services/utils";

const IEditCriterionModalFormProps = Vue.extend({
  props: {
    disabled: Boolean,
    id: Number,
    criterion: Object,
  },
});

interface OptionsRenderItem extends CriterionOptionsItem {
  idx: string;
}

@Component({
  components: {
    FormInput,
    SelectCriterionType,
    SelectCategory,
    ActionButton,
  },
})
export default class EditCriterionModalForm extends IEditCriterionModalFormProps {
  private componentClass = "EditCriterionModalForm";
  private name = "";
  private categoryId = 0;
  private criterionTypeSelectValue = "null";
  private deleteIcon: IconDefinition = faTimes;
  private knowledges = [
    {
      title: "",
      text: "",
    },
  ];

  private prohibitionOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: 0,
      idx: "prohibitionOption-0",
    },
    {
      name: "",
      nominalWeight: 0,
      idx: "prohibitionOption-1",
    },
  ];

  private requirementOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: 0,
      idx: "requirementOption-0",
    },
    {
      name: "",
      nominalWeight: 0,
      idx: "requirementOption-1",
    },
  ];

  private customOptions: OptionsRenderItem[] = [
    {
      name: "",
      nominalWeight: 0,
      idx: `customOption-0`,
    },
    {
      name: "",
      nominalWeight: 0,
      idx: "customOption-1",
    },
  ];

  mounted() {
    if (this.id) {
      this.getCriterion();
    }
    if (
      this.criterion &&
      this.criterion.notes &&
      this.criterion.notes.length > 0
    ) {
      this.knowledges = this.criterion.notes;
    }
  }

  get dataIsReady() {
    return !!this.$store.getters.getCriterionsSelectedCriterion;
  }

  get showAddMoreKnowledgesBtn() {
    const last = this.knowledges[this.knowledges.length - 1];
    return last.title.length > 2 && last.text.length > 2;
  }

  handleLength(value) {
    return value >= 1000 ? 1000 : value <= -1000 ? -1000 : value;
  }

  async getCriterion() {
    await this.$store.dispatch(
      StoreActionTypes.SET_CRITERIONS_SELECTED_CRITERION,
      this.id
    );

    const criterion: CriterionFullModel =
      this.$store.getters.getCriterionsSelectedCriterion;
    this.name = criterion.name;

    if (criterion.category) {
      this.categoryId = criterion.category.id;
    }

    if (criterion.typeCode) {
      this.criterionTypeSelectValue = criterion.typeCode;
    }

    if (this.criterionTypeSelectValue == "CUSTOM") {
      this.customOptions = this.fillOptionsRenderItemArray(
        criterion.options,
        "custom"
      );
    } else if (this.criterionTypeSelectValue == "PROHIBITION") {
      this.prohibitionOptions = this.fillOptionsRenderItemArray(
        criterion.options,
        "prohibition"
      );
    } else if (this.criterionTypeSelectValue == "REQUIREMENT") {
      this.requirementOptions = this.fillOptionsRenderItemArray(
        criterion.options,
        "requirement"
      );
    }
  }

  nameChange(text: string) {
    this.name = text;
  }

  public getEditCriterionRequestModel(): EditCriterionRequestModel {
    return {
      name: this.name,
      categoryId: this.categoryId,
      typeCode: this.getCriterionTypeSelectValue(),
      options: this.getOptions(),
      notes: this.knowledges
        .filter((v) => v.title.length > 0)
        .map((v) => {
          return {
            title: v.title,
            text: this.replaceURLs(v.text),
          };
        }),
    };
  }

  replaceURLs(message) {
    if (!message) return;

    const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      let hyperlink = url;
      if (!hyperlink.match("^https?://")) {
        hyperlink = "http://" + hyperlink;
      }
      return (
        '<a href="' +
        hyperlink +
        '" target="_blank" rel="noopener noreferrer">' +
        url +
        "</a>"
      );
    });
  }

  addKnowledge() {
    this.knowledges.push({
      title: "",
      text: "",
    });
  }

  handleCriterionTypeSelectChangeEvent(selected: string) {
    this.criterionTypeSelectValue = selected;
  }

  getCriterionTypeSelectValue(): CriterionTypeCode | null {
    if (this.criterionTypeSelectValue == "null") {
      return null;
    } else {
      return this.criterionTypeSelectValue as CriterionTypeCode;
    }
  }

  onSelectSelectCategory(id: number) {
    this.categoryId = id;
  }

  deleteCustomOption(index: number) {
    this.customOptions.splice(index, 1);
  }

  addCustomOptionItem() {
    const lastElementIndex = this.customOptions.length - 1;

    const lastElementIdx = parseInt(
      this.customOptions[lastElementIndex].idx.slice(
        this.customOptions[lastElementIndex].idx.indexOf("-") + 1
      )
    );

    this.customOptions.push({
      name: "",
      nominalWeight: 0,
      idx: `customOption-${lastElementIdx + 1}`,
    });
  }

  getOptions(): CriterionOptionsItem[] {
    const criterionType = this.criterionTypeSelectValue as CriterionTypeCode;
    let options: OptionsRenderItem[] = [];

    switch (criterionType) {
      case "CUSTOM":
        options = this.customOptions;
        break;
      case "REQUIREMENT":
        options = this.requirementOptions;
        break;
      case "PROHIBITION":
        options = this.prohibitionOptions;
        break;
    }

    return options.map((item) => ({
      name: item.name,
      nominalWeight: item.nominalWeight
        ? utils.convertCommaToDotAndRoundToTwo(item.nominalWeight.toString())
        : 0,
    }));
  }

  fillOptionsRenderItemArray(
    options: CriterionOptionsItem[],
    label: string
  ): OptionsRenderItem[] {
    return options.map((option, index) => ({
      name: option.name,
      nominalWeight: option.nominalWeight,
      idx: `${label}Option-${index}`,
    }));
  }
}
