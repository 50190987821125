
import Vue from "vue";
import Component from "vue-class-component";
import FormInput from "@/components/lib/input/FormInput.vue";
import SelectCriterionType from "@/components/select/criterionType/SelectCriterionType.vue";
import SelectCategory from "@/components/select/category/SelectCategory.vue";
import ActionButton from "@/components/lib/button/ActionButton.vue";
import {
  ChangeCategoryStatusResponseModel,
  ChangeCategoryResponseModel,
  StatusType,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";

const IEditCriterionGroupModalFormProps = Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    selectedCategoryId: {
      type: Number,
      required: false,
      default: null,
    },
    category: {
      type: Object,
    },
    nestedList: {
      type: Array,
      required: true,
    },
  },
});

@Component({
  components: {
    FormInput,
    SelectCriterionType,
    SelectCategory,
    ActionButton,
  },
})
export default class EditCriterionGroupModalForm extends IEditCriterionGroupModalFormProps {
  private componentClass = "EditCriterionGroupModalForm";
  private name = "";
  private statusSelections = ["ACTIVE", "INACTIVE"];
  private selectedStatus = "";

  public getEditCriterionGroupRequestModel(): any {
    return {
      id: this.category.id,
      name: this.name,
      status: this.selectedStatus,
    };
  }

  mounted() {
    this.selectedStatus = this.category.status;
    this.name = this.category.name;
  }

  nameChange(text: string) {
    this.name = text;
  }

  onSelect(item) {
    this.selectedStatus = item;
  }

  async removeFromCheckList() {
    let ids = this.nestedList
      .map((v: any) => {
        return v.criterions.map((t) => t.id);
      })
      .flat();

    const thisIds = this.category.criterions.map((t) => t.id);

    ids = ids.filter((val) => !thisIds.includes(val));

    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_EDIT_CHECKLIST_CRITERIONS_LIST,
      { ids }
    );

    this.$emit("close");
  }
}
