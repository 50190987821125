
import { CategoriesInCheckListModel, SelectModel } from "@/services/types";
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import { IconDefinition, faCaretDown } from "@fortawesome/free-solid-svg-icons";

const ICategoryWithCriterionsListProps = Vue.extend({
  props: {
    list: {
      type: Object as PropType<CategoriesInCheckListModel>,
      required: true,
    },

    checkListItems: {
      type: Array,
      required: true,
    },
  },
});

@Component
export default class CategoryWithCriterionsList extends ICategoryWithCriterionsListProps {
  private componentClass = "CategoryWithCriterionsList";
  private show = false;
  private headerIcon: IconDefinition = faCaretDown;
  public selected: string[] = [];
  public selectionStatus = "";
  private options: SelectModel[] = [];

  private categoryList: CategoriesInCheckListModel = {
    selectionStatus: "deselected",
    id: null,
    criterions: [],
    name: "",
  };
  mounted() {
    this.categoryList = this.list;

    this.categoryList.criterions.map((item) => {
      this.options.push({
        value: item.id.toString(),
        text: item.name,
      });

      if (item.selectionStatus) {
        this.selected.push(item.id.toString());
      }
    });
    this.setSelectedCriterions();
    this.categorySelectedCriterionsListener();

    if (this.selectionStatus != "deselected") {
      this.show = true;
    }
  }

  get setVisible(): string {
    return this.show ? "visible" : "";
  }

  public getSelectedList(): number[] {
    return this.selected.map((item) => parseInt(item));
  }

  get showSelectedIcon(): boolean {
    return (
      this.categoryList.selectionStatus == "custom" ||
      this.categoryList.selectionStatus == "selected"
    );
  }

  handleCategoryStatusCheckBoxClick() {
    this.selected = [];
    this.show = true;

    if (this.selectionStatus == "deselected") {
      this.selectionStatus = "selected";

      this.options.map((item) => {
        this.selected.push(item.value);
      });
    } else {
      this.selectionStatus = "deselected";
    }
  }

  setSelectedCriterions() {
    this.checkListItems.forEach((item: any) => {
      item.criterions.forEach((criterion) => {
        if (
          this.options.findIndex((v) => v.value === criterion.id.toString()) !==
            -1 &&
          this.selected.indexOf(criterion.id.toString()) === -1
        ) {
          this.selected.push(criterion.id.toString());
        }
      });
    });
  }

  get getCategoryStatus() {
    return this.categoryList.selectionStatus;
  }

  categorySelectedCriterionsListener() {
    if (this.selected.length == 0) {
      return (this.selectionStatus = "deselected");
    } else if (
      this.selected.length > 0 &&
      this.selected.length === this.options.length
    ) {
      return (this.selectionStatus = "selected");
    }

    this.selectionStatus = "custom";
  }
}
